import React from "react";
import { graphql } from "gatsby";
import { Pagination } from "../components/pagination";
import { ImageGallery } from "../components/imageGallery";
import { ParentPage } from "../components/parentPage";

const ParentImageGallery = ({
  data,
  pageContext,
}: {
  pageContext: {
    limit: number;
    skip: number;
    prev?: string;
    next?: string;
  };
  data: {
    allSanityParentImageGallery: {
      edges: [];
    };
  };
}) => (
  <ParentPage>
    <div className="mx-4">
      <h1 className="mb-6 mt-4">Image Galleries</h1>
      {data.allSanityParentImageGallery.edges.map((edge, i) => (
        <div key={i} className="text-center mb-10">
          <h4>{edge.node.title}</h4>
          <span>
            {new Date(edge.node.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </span>
          <ImageGallery images={edge.node._rawImages} />
        </div>
      ))}
      <Pagination {...pageContext} />
    </div>
  </ParentPage>
);

export const query = graphql`
  query sanityParentImageGallery($skip: Int!, $limit: Int!) {
    allSanityParentImageGallery(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          _rawImages(resolveReferences: { maxDepth: 10 })
          title
          _rawSlug
          date
        }
      }
    }
  }
`;

export default ParentImageGallery;
